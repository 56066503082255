import Card from "./Card";

export default class FlashcardsSet {
  url: string;
  socialImg: string;
  title: string;
  cards: Array<Card>;

  constructor(data: any) {
    this.url = data.url;
    this.socialImg = data.socialImg;
    this.title = data.title;
    this.cards = data.cards.map((cardData: any) => new Card(cardData));
  }
}
