import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Animated,
} from "react-native";
import Icon from "react-native-vector-icons/AntDesign";
// @ts-ignore
import CardObj from "../Objects/Card";

const Card = (card: CardObj, showBack: boolean, handlePress: any) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={handlePress} activeOpacity={1}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={styles.frontText} numberOfLines={undefined}>
            {card.front}
          </Text>
          <TouchableOpacity
            onPress={() => {
              card.frontAudio.play();
            }}
          >
            <Icon name="sound" style={styles.frontText} />
          </TouchableOpacity>
        </View>
        <Animated.View
          style={{
            opacity: showBack ? 1 : 0,
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <Text style={styles.backText} numberOfLines={undefined}>
            {card.back}
          </Text>
          <TouchableOpacity
            onPress={() => {
              card.backAudio.play();
            }}
          >
            <Icon name="sound" style={styles.backAudio} />
          </TouchableOpacity>
        </Animated.View>
      </TouchableOpacity>
    </View>
  );
};

type Props = {
  cardData: Array<CardObj>;
  currentIndex: number;
  showBack: boolean;
  handlePress: any;
};

const CardSlider: React.FC<Props> = ({
  cardData,
  currentIndex,
  showBack,
  handlePress,
}) => {
  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View
        style={[
          styles.buttonContainer,
          { opacity: currentIndex === 0 ? 0.25 : 1 },
        ]}
      ></View>
      <View style={{ flex: 2 }}>
        {Card(cardData[currentIndex], showBack, handlePress)}
      </View>
      <View
        style={[
          styles.buttonContainer,
          { opacity: currentIndex === cardData.length - 1 ? 0.25 : 1 },
        ]}
      ></View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 20,
  },
  button: {
    backgroundColor: "#FAF9F6",
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 15,
  },
  frontText: {
    color: "black",
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
    paddingRight: 5,
  },
  backText: {
    color: "grey",
    fontWeight: "500",
    fontSize: 14,
    textAlign: "center",
    paddingRight: 5,
  },
  backAudio: {
    color: "grey",
    fontWeight: "500",
    fontSize: 16,
    textAlign: "center",
    paddingRight: 5,
  },
});

export default CardSlider;
