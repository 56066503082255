// @ts-ignore
import { Audio } from "expo-av";

const QUIZLET_DOMAIN = "https://quizlet.com";

const enableAudio = async () => {
  await Audio.setAudioModeAsync({
    playsInSilentModeIOS: true,
    staysActiveInBackground: false,
    interruptionModeAndroid: 1,
    shouldDuckAndroid: false,
  });
};

export default class AudioPlayer {
  private sound: Audio.Sound | null = null;
  private isPlaying: boolean = false;

  constructor(private uri: string) {}

  async play() {
    if (this.sound === null) {
      const { sound } = await Audio.Sound.createAsync({
        uri: this.getFullUrl(),
      });
      this.sound = sound;
    }

    try {
      enableAudio();
      await this.sound.replayAsync();
      this.isPlaying = true;
      console.log("Successfully played sound");
    } catch (error) {
      console.log("Failed to play sound:", error);
    }
  }

  async stop() {
    if (this.sound && this.isPlaying) {
      try {
        await this.sound.stopAsync();
        this.isPlaying = false;
        console.log("Stopped sound");
      } catch (error) {
        console.log("Failed to stop sound:", error);
      }
    }
  }

  async release() {
    if (this.sound !== null) {
      try {
        await this.sound.unloadAsync();
        this.sound = null;
        console.log("Released sound");
      } catch (error) {
        console.log("Failed to release sound:", error);
      }
    }
  }

  private getFullUrl() {
    if (this.uri.startsWith("/")) {
      return QUIZLET_DOMAIN + this.uri;
    }
    return this.uri;
  }
}
